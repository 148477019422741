import React, { Component } from "react";
import { Link } from "gatsby";
import UserLinks from "../UserLinks/UserLinks";
import "./Sidebar.scss";

class Sidebar extends Component {
  render() {
    const { config } = this.props;
    const profile = config.profilePhoto;
    const { copyright } = config;
    if (!copyright) {
      return null;
    }
    return (
      <aside className="sidebar">
        {profile ? <a href="/" className="profile"><img src={profile} alt={config.siteTitle} /></a> : ""}
        {config.siteTitle ? <h1><a href="/">{config.siteTitle}</a></h1> : ""}
        <UserLinks config={config} />
        <nav className="tag-links">
          <a href="/tags/daily/" className="nav-link">daily</a>
          <a href="/tags/fashion/" className="nav-link">fashion</a>
          <a href="/tags/reads/" className="nav-link">reads</a>
        </nav>
      </aside>
    );
  }
}

export default Sidebar;
